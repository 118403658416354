/* eslint-disable max-depth */
import * as R from 'ramda';
import { isEmpty } from 'lodash';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  DATA_DETAIL_OPTIONS_FETCH_SUCCESS,
  DATA_DETAIL_OPTIONS_FETCH_ERROR,
  DATA_DETAIL_FETCH_INIT,
  DATA_DETAIL_FETCH_SUCCESS,
  DATA_DETAIL_FETCH_ERROR,
  DATA_DETAIL_SAVE_INIT,
  DATA_DETAIL_SAVE_SUCCESS,
  DATA_DETAIL_SAVE_ERROR,
  DATA_DETAIL_SET,
  DATA_DETAIL_ERROR_FIELD,
  DATA_DETAIL_UPDATE_FIELD,
  DATA_DETAIL_CREATE_SEGMENT,
  DATA_DETAIL_SWAP_FIELDS,
  DATA_DETAIL_CLEAR_SCHEDULE,
  DATA_DETAIL_CLONE_SEGMENT,
  DATA_DETAIL_DELETE_SEGMENT,
  DATA_DETAIL_SEGMENT_UPDATE_FIELD,
  DATA_DETAIL_SEGMENT_UPDATE,
  DATA_DETAIL_COMPUTE_SEGMENT_START,
  DATA_DETAIL_COMPUTE_SEGMENT_SUCCESS,
  DATA_DETAIL_COMPUTE_SEGMENT_ERROR,
  DATA_DETAIL_UPDATE_POLYGON,
  DATA_DETAIL_DELETE_SUCCESS,
  DATA_DETAIL_DELETE_ERROR,
  DATA_DETAIL_MODIFIED,
  DATA_DETAIL_SET_ACTIVE_TAB,
  DATA_DETAIL_FETCH_CYCLE_SUCCESS,
  DATA_DETAIL_FETCH_CYCLE_ERROR,
  DATA_DETAIL_FETCH_OVERLAP_ENTITIES_SUCCESS,
  DATA_DETAIL_FETCH_OVERLAP_ENTITIES_ERROR,
  DATA_DETAIL_SCROLL_TO_FIELD,
  GROUPS_REMOVE_ENTITY_SUCCESS
} from '@constants/action-types';
import { getDetailsConfig } from '@constants/config';
import initialState from '../store/initial-state';
import {
  createEmptySegment,
  createTemporalId,
  splitAssocPath
} from '@utils/data-detail-utils';
import { shouldClearData } from '@utils/navigation-utils';

const dataDetailReducer = (state = initialState.dataDetail, action) => {
  switch (action.type) {
  case DATA_DETAIL_OPTIONS_FETCH_SUCCESS: {
    const options = action.payload.data;
    return {...state, options};
  }
  case DATA_DETAIL_OPTIONS_FETCH_ERROR: {
    return {...state, options: null, error: action.payload.data};
  }
  case DATA_DETAIL_FETCH_INIT: {
    return {...state, loading: true, saving: false};
  }
  case DATA_DETAIL_FETCH_SUCCESS: {
    const { dataType, isNew, data } = action;
    const newState = {
      ...state,
      data,
      dataType,
      error: {},
      loading: false
    };
    if (isNew && data.segments) {
      return {
        ...newState,
        data: {
          ...data,
          segments: [createEmptySegment()]
        }
      };
    }
    return { ...newState };
  }
  case DATA_DETAIL_SET: {
    const { dataType, data, errors } = action;
    return {
      ...state,
      data,
      dataType,
      error: errors
    };
  }
  case DATA_DETAIL_FETCH_ERROR: {
    return {...state, data: null, error: action.payload.data, loading: false};
  }
  case DATA_DETAIL_SAVE_INIT: {
    return {...state, saving: true};
  }
  case DATA_DETAIL_SAVE_SUCCESS: {
    const data = action.payload.data;
    return {...state, data, error: {}, saving: false, modified: false};
  }
  case DATA_DETAIL_SAVE_ERROR: {
    return {...state, error: action.payload.data, saving: false};
  }
  case DATA_DETAIL_FETCH_CYCLE_SUCCESS: {
    return {...state, cycles: [...action.payload.results], cyclesErrors: null };
  }
  case DATA_DETAIL_FETCH_CYCLE_ERROR: {
    return {...state, cycles: [], cyclesErrors: { ...action.error } };
  }
  case DATA_DETAIL_FETCH_OVERLAP_ENTITIES_SUCCESS: {
    return {...state, overlapEntities: { ...action.payload }, overlapErrors: null };
  }
  case DATA_DETAIL_FETCH_OVERLAP_ENTITIES_ERROR: {
    return {...state, overlapEntities: { ...action.payload }, overlapErrors: { ...action.error } };
  }
  case DATA_DETAIL_SCROLL_TO_FIELD: {
    const { scrollId } = action;
    return { ...state, scrollId };
  }
  case DATA_DETAIL_UPDATE_FIELD: {
    const { fieldName, value } = action;
    const newError = { ...state.error };
    // When we update a field in the form, we must clear the error,
    // since it's an error set on the backend after the save's validation,
    // and modifying it, means we might have solved the problem.
    //
    // However, if the error is set through front-end validation, we
    // must not clear it (it will be cleared after front-end validation
    // runs again).
    //
    // state.frontendErrorFields stores all fields which contains
    // front-end validation errors, so we only clear the error
    // if the current field is not on that list.
    if (!state.frontendErrorFields.has(fieldName)) {
      // If the field name contains a dot, then it's actually a nested field
      // (like "contact.email", i.e. "contact: { email: 'xxxx', name: 'xxxx' }").
      if (fieldName.includes('.')) {
        const fields = fieldName.split('.');  // Get the fields path.
        // If the nested field and parent exists in the error list:
        if (newError[fields[0]] && newError[fields[0]][fields[1]]) {
          // Delete the "leaf", and...
          delete newError[fields[0]][fields[1]];
          // If the parent is empty, delete it too
          // (i.e. in the example in which we have contact.email and contact.name
          // if both exists, we cannot delete "contact", but if after deleting
          // email, "contact" is empty, we should clear that empty entry from
          // the error list.
          if (isEmpty(newError[fields[0]])) {
            delete newError[fields[0]];
          }
        }
      }
      // Be it a nested field or not, always attempt to clear the error
      // for the full field name.
      delete newError[fieldName];
    }
    const path = ['data', ...splitAssocPath(fieldName)];
    return {
      ...R.assocPath(path, value, state),
      error: newError
    };
  }
  case DATA_DETAIL_CREATE_SEGMENT: {
    return {
      ...state,
      data: {
        ...state.data,
        segments: [
          createEmptySegment(),
          ...state.data.segments
        ]
      },
      modified: true
    };
  }
  case DATA_DETAIL_SWAP_FIELDS: {
    const { from, id, to } = action;
    const segmentIndex = state.data.segments.findIndex(segment => segment.id === id);
    const segments = [...state.data.segments];
    const newSegment = {...state.data.segments[segmentIndex]};
    const tempFrom = newSegment[from];
    newSegment[from] = newSegment[to];
    newSegment[to] = tempFrom;
    if (newSegment.shape) {
      const coordinates = [...newSegment.shape.coordinates];
      const tempCoord = coordinates[0];
      const lastPos = coordinates.length - 1;
      coordinates[0] = coordinates[lastPos];
      coordinates[lastPos] = tempCoord;
      newSegment.shape = {...newSegment.shape, coordinates};
    }
    segments.splice(segmentIndex, 1, newSegment);
    return {...state, data: {...state.data, segments}};
  }
  case DATA_DETAIL_CLEAR_SCHEDULE: {
    const { id } = action;
    const segmentIndex = state.data.segments.findIndex(seg => seg.id === id);
    const segments = [...state.data.segments];
    const updatedSegment = {...state.data.segments[segmentIndex]};
    delete updatedSegment.schedules;
    segments.splice(segmentIndex, 1, updatedSegment);
    return {...state, data: {...state.data, segments}};
  }
  case DATA_DETAIL_CLONE_SEGMENT: {
    const { id } = action;
    const newSegments = [];
    state.data.segments.forEach(segment => {
      newSegments.push(segment);
      if (segment.id === id) {
        const newSchedules = [];
        if (!isEmpty(segment.schedules)) {
          const schedule = segment.schedules[0];
          newSchedules.push({
            ...schedule,
            id: createTemporalId(),
            exceptions: [
              ...(schedule.exceptions || []).map(exception => ({
                ...exception,
                id: createTemporalId()
              }))
            ],
            recurrences: [
              ...(schedule.recurrences || []).map(recurrence => ({
                ...recurrence,
                id: createTemporalId()
              }))
            ]
          });
        }
        const newSegment = {
          ...segment,
          schedules: newSchedules,
          id: createTemporalId()
        };
        newSegments.push(newSegment);
      }
    });
    return {
      ...state,
      data: {...state.data, segments: newSegments}
    };
  }
  case DATA_DETAIL_DELETE_SEGMENT: {
    const { id } = action;
    const newSegments = [];
    const {segments: originalSegmentErrors, ...newError} = {...state.error};
    const newSegmentsErrors = [];
    state.data.segments.forEach((segment, index) => {
      if (segment.id !== id) {
        newSegments.push(segment);
        if (originalSegmentErrors) {
          newSegmentsErrors.push(state.error.segments[index] || {});
        }
      }
    });
    if (newSegmentsErrors.length > 0) {
      newError.segments = newSegmentsErrors;
    }
    const deletedSegmentIds = (state.data.deletedSegmentIds || []).concat(isNaN(id) ? [] : [id]);
    return {
      ...state,
      data: {...state.data, segments: newSegments, deletedSegmentIds},
      error: newError
    };
  }
  case DATA_DETAIL_SEGMENT_UPDATE_FIELD: {
    const {segmentId, fieldName, value, error} = action;
    const segmentIndex = state.data.segments.findIndex(segment => segment.id === segmentId);
    const segments = [...state.data.segments];
    const errorField = `${fieldName}_error`;
    const newSegment = R.omit([errorField], {...state.data.segments[segmentIndex]});
    if (error) {
      newSegment[errorField] = error;
    } else {
      newSegment[fieldName] = value;
    }
    segments.splice(segmentIndex, 1, newSegment);
    return {...state, data: {...state.data, segments}};
  }
  case DATA_DETAIL_SEGMENT_UPDATE: {
    const { segment } = action;
    const { id } = segment;
    const segmentIndex = state.data.segments.findIndex(seg => seg.id === id);
    const segments = [...state.data.segments];
    segments.splice(segmentIndex, 1, segment);
    return {...state, data: {...state.data, segments}};
  }
  case DATA_DETAIL_COMPUTE_SEGMENT_START: {
    const segmentIndex = state.data.segments.findIndex(segment => segment.id === action.segmentId);
    const segments = [...state.data.segments];
    const newSegment = {...state.data.segments[segmentIndex], updateId: action.updateId, shape: null};
    segments.splice(segmentIndex, 1, newSegment);
    return {...state, data: {...state.data, segments}};
  }
  case DATA_DETAIL_COMPUTE_SEGMENT_SUCCESS: {
    const segmentIndex = state.data.segments.findIndex(segment => segment.id === action.segment.id);
    const {updateId, ...originalSegment} = state.data.segments[segmentIndex];
    // Only update segment if action is the latest action (and still valid)
    if (updateId && updateId === action.updateId) {
      const newSegment = {...originalSegment, ...action.segment};
      const segments = [...state.data.segments];
      segments.splice(segmentIndex, 1, newSegment);

      // When we update segments, we might have backend validated errors
      // (which are set when saving the whole entity),
      // thus we must clear them, since the field is now filled.
      const newError = { ...state.error, segments: [] };

      if (state.error.segments) {
        // Clear the current segment, by adding an empty object
        // to the current index (this is what the backend returns
        // when a segment has no errors).
        state.error.segments.forEach((segment, index) => {
          if (index === segmentIndex) {
            newError.segments.push({});
          } else {
            // And add the other segment errors.
            newError.segments.push(segment);
          }
        });
      }

      return {
        ...state,
        data: {...state.data, segments},
        error: newError
      };
    }
    return state;
  }
  case DATA_DETAIL_ERROR_FIELD: {
    const { clear, error, fieldName } = action;
    const newError = {
      ...state.error,
      [fieldName]: [error]
    };
    const newFrontendErrorFields = new Set(state.frontendErrorFields);
    // By setting the 'clear' flag to false, we tell that this field must
    // not be added to the list of fields to "clear".
    if (!clear) {
      newFrontendErrorFields.add(fieldName);
    }
    // Remove the error key if the error cleared:
    if (error === null) {
      delete newError[fieldName];
    }
    return {
      ...state,
      error: newError,
      // Add the fields to the store, specifying that
      // we are setting it as an error from the frontend,
      // thus it cannot be cleared if the field is modified,
      // it can only be cleared manually after validation.
      frontendErrorFields: newFrontendErrorFields
    };
  }
  case DATA_DETAIL_COMPUTE_SEGMENT_ERROR: {
    return {...state, error: action.error};
  }
  case DATA_DETAIL_UPDATE_POLYGON: {
    const segmentIndex = state.data.segments.findIndex(segment => segment.id === action.segmentId);
    const segments = [...state.data.segments];
    const newSegment = {...state.data.segments[segmentIndex], shape: action.shape, lastModifiedBy: action.changeSource};
    segments.splice(segmentIndex, 1, newSegment);
    return {...state, data: {...state.data, segments}};
  }

  case DATA_DETAIL_DELETE_SUCCESS: {
    return {...state, data: {}};
  }
  case DATA_DETAIL_DELETE_ERROR: {
    return {...state, error: action.error};
  }
  case GROUPS_REMOVE_ENTITY_SUCCESS: {
    const { groupId, entityId } = action.payload;
    if (state.data && state.data.id === groupId && state.data.entities) {
      const newEntities = state.data.entities.filter(entity => entity.id !== entityId);
      return { ...state, data: { ...state.data, entities: newEntities } };
    }
    return state;
  }
  // Listen to location changes and clear the state, data is always loaded
  // later, thus if we don't clear it, we are initially displaying old data.
  case LOCATION_CHANGE: {
    if (action?.payload) {
      const { location: { pathname, state: locationState } } = action.payload;
      // If we pushed something with browserHistory with a 'clear' flag, it means
      // we wanted to clear the data directly:
      if (locationState?.clear) {
        return initialState.dataDetail;
      }
      const detailsConfig = getDetailsConfig();
      const detailPages = [
        'cycle', 'group', 'overlaps', 'overlap',
        ...(detailsConfig ? Object.keys(detailsConfig) : [])
      ];
      const isDetailPage = path => detailPages.find(page => path.startsWith(`/${page}/`));
      if (isDetailPage(pathname) && shouldClearData(state, pathname)) {
        return initialState.dataDetail;
      }
    }
    // After changing the locations, even if we don't reset the whole state,
    // reset the errors:
    return {
      ...state,
      error: {}
    };
  }
  case DATA_DETAIL_MODIFIED: {
    return {...state, modified: action.modified };
  }
  case DATA_DETAIL_SET_ACTIVE_TAB: {
    const { dataType, tab } = action;
    return {
      ...state,
      currentTab: {
        ...state.currentTab,
        [dataType]: tab
      }
    };
  }
  default:
    return state;
  }
};

export default dataDetailReducer;
