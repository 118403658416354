import React, { memo, useContext } from 'react';

import { useSelector } from 'react-redux';

import { capitalize } from 'lodash';

import ParamsContext from '@components/entity/info/params-context';

import {
  renderWarning,
  showEntityScheduleContainsWarning
} from '@utils/segment-schedule/common';

import '../../../../../forms/forms.scss';

const ScheduleWarning = () => {
  const { dataType } = useContext(ParamsContext);
  const { data } = useSelector(state => state.dataDetail);

  if (showEntityScheduleContainsWarning(data.start_date, data.end_date, data.segments)) {
    return (
      <div styleName="form-warning">
        {renderWarning(`${capitalize(dataType)} schedule does not contain all location schedules.`)}
      </div>
    );
  }
  return null;
};

export default memo(ScheduleWarning);
