/* eslint-disable react/jsx-no-bind */
import React, { memo, useContext, useMemo } from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import FormField from '@components/entity/info/drawer/form/form-tab/form-field';
import SegmentTitle from '@components/entity/info/drawer/form/form-tab/segment-title';
import Title from '@components/entity/info/drawer/form/form-tab/title';
import ParamsContext from '@components/entity/info/params-context';

import { isReadOnly } from '@utils/permission-utils';

import '../../../../../forms/forms.scss';

const FormTab = ({ tab }) => {
  const { dataType } = useContext(ParamsContext);
  const { permissions } = useSelector(state => state.config);
  const readOnly = useMemo(() => isReadOnly(dataType, permissions), [dataType, permissions]);
  return (
    <div key={`tab-section-${tab.id}`} styleName="form-tab">
      {tab.type !== 'segment' && <Title tab={tab} />}
      {tab.type === 'segment' && <SegmentTitle readOnly={readOnly} tab={tab} />}
      <div>
        {tab.fields.map(fieldName => (
          <FormField fieldName={fieldName} key={fieldName} tab={tab} />
        ))}
      </div>
    </div>
  );
};

FormTab.propTypes = {
  tab: PropTypes.object
};

export default memo(FormTab);
