/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';

import {
  deleteAttachment,
  getAttachments
} from '@actions/attachment-actions';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { fetchOverlapEntities } from '@actions/data-detail-actions';
import { openGroupAddEntityDialog } from '@actions/groups-actions';
import {
  setOverlapHoverEntity,
  selectSegment
} from '@actions/map-actions';

import DetailsTab from '@components/map/tray/entity-tabs/details-tab';
import FilesTab from '@components/map/tray/entity-tabs/files-tab';
import OverlapsTab from '@components/map/tray/entity-tabs/overlaps-tab';

import { dotmapsGreen } from '@constants/colors';
import {
  getEntityTypeLabel,
  getTrayConfig,
  isWorkflowEnabled,
  isTimeEnabled
} from '@constants/config';
import { trayHeaderConfig } from '@constants/component-configs';
import * as dialog from '@constants/dialogs';
import { mapTrayStyles } from '@constants/mui-theme';
import Header from '@components/map/tray/header';

import EntityTypeIcon from '@icons/entity-type-icon';

import { Icon } from '@mui';

import { getEntityDetailsTrayConfig } from '@selectors/tray-selector';
import { getTrayTop } from '@selectors/map-selector';

import DotmapsLoader from '@shared/dotmaps-loader';
import FieldsLinkButton from '@shared/fields-link-button';
import DefaultDate from '@shared/formatted-date-helper';
import DefaultDatetime from '@shared/formatted-datetime-helper';
import { Tabs, Tab } from '@shared/tabs';

import { getEntityLegend, PriorityIcon } from '@utils/icon-utils';
import { daysLong, getStreetViewBackground } from '@utils/map-utils';
import { overlapLink } from '@utils/overlap-utils';
import {
  canAccessFiles,
  canAccessOverlaps,
  canAccessWorkflow,
  canEditGroups,
  canViewEntityPage,
  isReadOnly
} from '@utils/permission-utils';

import './tray.scss';

const Entity = () => {
  const tray = useSelector(getTrayTop);
  const { permissions } = useSelector(state => state.config);
  const agencyTypes = useSelector(state => state.dataTypes.agency_type);
  const attachmentState = useSelector(state => state.attachments);
  const { overlapErrors, overlapEntities } = useSelector(state => state.dataDetail);
  const entityDetailsTrayConfig = useSelector(getEntityDetailsTrayConfig);
  const [activeTab, setActiveTab] = useState('details');
  const [scrolling, setScrolling] = useState(false);
  const tabsElement = useRef(null);
  const dispatch = useDispatch();

  const trayData = (tray.trayType === 'entity' ? tray.data : null);
  const { entity = {}, pending, position } = trayData || {};
  const { entity_type: entityType } = entity || {};
  const attachments = attachmentState.attachments[`entity${entity?.id}`] || [];
  const trayConfig = getTrayConfig()[entityType] || {};

  const setDetailsActive = () => setActiveTab('details');
  const setOverlapsActive = () => setActiveTab('overlaps');
  const setFilesActive = () => setActiveTab('files');

  const hasOverlaps = useCallback(() => {
    const conflicts = !entity.conflicts || entity.conflicts === '' ? 0 : entity.conflicts.split(',').length;
    const opportunities = !entity.opportunities || entity.opportunities === '' ? 0 : entity.opportunities.split(',').length;
    return (conflicts + opportunities) > 0;
  }, [entity.conflicts, entity.opportunities]);

  useEffect(() => {
    if (!pending) {
      dispatch(getAttachments('entity', entity.id));
      if (hasOverlaps()) {
        dispatch(fetchOverlapEntities(entity.id));
      }
      if (activeTab !== 'details') {
        setDetailsActive();
      }
    }
  }, [entity?.id, pending]);  // eslint-disable-line react-hooks/exhaustive-deps

  if (!trayData || R.isEmpty(trayData)) {
    return null;
  }

  if (pending) {
    return (
      <div styleName="map-tray-body-wrapper">
        <Header />
        <DotmapsLoader color={dotmapsGreen} display small />;
      </div>
    );
  }

  const onScroll = event => {
    // There's an issue where shrinking the header causes the scrollbar to disappear, which
    // then cause it to expand, making it flicker back and forth between the two
    // so don't allow the header to shrink if unless the scrollable area is bigger
    // than the client height + the difference in header size (152px)
    const headerHeightDifference = trayHeaderConfig.fullHeight - trayHeaderConfig.scrollingHeight;
    if ((tabsElement.current.clientHeight + headerHeightDifference < event.target.scrollHeight) || scrolling) {
      setScrolling(event.target.scrollTop > 0);
    }
  };

  const onDeleteAttachment = index => {
    if (attachments && attachments.length > index) {
      const attachment = attachments[index];
      dispatch(deleteAttachment(attachment.id));
    }
  };

  const addToGroup = () => {
    dispatch(openGroupAddEntityDialog(entityType, entity));
  };

  const startCycle = () => {
    const params = { entityId: entity.id };
    dispatch(openDashboardDialog(dialog.START_CYCLE, params));
  };

  const getMoreActions = () => {
    const actions = [];
    const entityTypeLabel = getEntityTypeLabel(entityType);
    const { id } = entity;
    if (canViewEntityPage(entity.agency, entityType)) {
      const readOnly = isReadOnly(entityType, permissions);
      const label = readOnly ? 'View' : 'Edit';
      actions.push({ label: `${label} ${entityTypeLabel}`, link: `/${entityType}/${id}` });
      if (!readOnly) {
        actions.push({ label: 'Duplicate', link: `/${entityType}/${id}/duplicate` });
      }
    }
    actions.push({ label: 'View activity log', link: `/logs/map/entity/${id}` });
    if (canEditGroups()) {
      actions.push({ label: 'Add to group', action: addToGroup });
    }
    if (isWorkflowEnabled() && canAccessWorkflow('cycle', 'add')) {
      actions.push({ label: 'Start a workflow', action: startCycle });
    }
    return actions;
  };

  const getSubHeaderDate = () => {
    const { start_date: startDate, end_date: endDate} = entity;
    const days = daysLong(startDate, endDate);
    const hasDate = startDate && endDate;
    const displayTime = isTimeEnabled();

    if (hasDate) {
      return (
        <div>
          {displayTime ?
            <span styleName="date-range"><DefaultDatetime value={startDate} />–<DefaultDatetime value={endDate} /></span> :
            <span><DefaultDate value={startDate} />–<DefaultDate value={endDate} /></span>
          }
          {days && !scrolling && <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;{days}</span>}
        </div>
      );
    }

    return <div>Start/end dates unspecified.</div>;
  };

  const getSubHeader = () => {
    const linkData = trayConfig['link-button'];
    const priority = entity?.category_dict?.find(category => category.name === 'priority');
    return (
      <div>
        <div styleName="map-tray-subheader-header">
          <Icon styleName="icon">
            <EntityTypeIcon type={entityType} size="0.8125rem" />
          </Icon>
          <span styleName="id">ID {entity.id}</span>
          {priority && <PriorityIcon priority={priority.id} />}
        </div>
        <div
          data-testid="map-tray-subheader-body"
          styleName={`map-tray-subheader-body ${scrolling ? 'scrolling' : ''}`}
        >
          {entity.name}
        </div>
        <div styleName="map-tray-subheader-footer">
          {getSubHeaderDate()}
        </div>
        {linkData && <FieldsLinkButton entity={entity} link={linkData} />}
      </div>
    );
  };

  const clearSelection = () => {
    dispatch(setOverlapHoverEntity());
    dispatch(selectSegment());
  };

  const showFiles = () => R.pathOr(true, [entityType, 'files'], trayConfig) && canAccessFiles(entity.agency);

  const getTabs = () => {
    const { conflicts, opportunities } = overlapEntities[entity.id] || { conflicts: [], opportunities: [] };
    return (
      <Tabs
        {...mapTrayStyles.tabs}
        onChange={clearSelection}
        onScroll={onScroll}
        value={activeTab}
      >
        <Tab
          data-testid="map-tray-tab-details"
          {...(activeTab === 'details' ? mapTrayStyles.activeTab : mapTrayStyles.tab)}
          label="Details"
          onActive={setDetailsActive}
          value="details"
        >
          <DetailsTab
            detailsTrayConfig={entityDetailsTrayConfig[entityType]}
            entity={entity}
            type="entity"
          />
        </Tab>
        {overlapErrors === null && (hasOverlaps() && canAccessOverlaps(entity.agency)) &&
          <Tab
            data-testid="map-tray-tab-overlaps"
            {...(activeTab === 'overlaps' ? mapTrayStyles.activeTab : mapTrayStyles.tab)}
            label="Overlaps"
            onActive={setOverlapsActive}
            value="overlaps"
          >
            <OverlapsTab
              detailUrl={overlapLink(entity.id)}
              conflicts={conflicts}
              opportunities={opportunities}
              tabActive={activeTab === 'overlaps'}
              entityType={entityType}
            />
          </Tab>
        }
        {showFiles() &&
          <Tab
            data-testid="map-tray-tab-files"
            {...(activeTab === 'files' ? mapTrayStyles.activeTab : mapTrayStyles.tab)}
            label="Files"
            onActive={setFilesActive}
            value="files"
          >
            <FilesTab
              dataType="entity"
              entity={entity}
              attachments={attachments}
              deleteAttachment={onDeleteAttachment}
            />
          </Tab>
        }
      </Tabs>
    );
  };

  const getHeaderIcon = () => {
    const agencyType = agencyTypes[entity.agency_type];
    return getEntityLegend(agencyType, entityType, entity.iconId);
  };

  return (
    <div styleName="map-tray-body-wrapper">
      <Header
        title={entity.title}
        iconUrl={getHeaderIcon()}
        moreActions={getMoreActions()}
      />
      <div styleName={`scrolling-container ${scrolling ? 'scrolling' : ''}`}>
        {position &&
          <div
            style={getStreetViewBackground(position)}
            styleName={`streetview-header ${scrolling ? 'scrolling' : ''}`} />
        }
        <div styleName={`map-tray-subheader ${scrolling ? 'scrolling' : ''}`}>{getSubHeader()}</div>
      </div>
      <div styleName="map-tray-tabs" ref={tabsElement}>
        {getTabs()}
      </div>
    </div>
  );
};

export default memo(Entity);
