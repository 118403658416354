import React, { memo, useCallback, useMemo } from 'react';

import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { saveAndStay } from '@actions/data-detail-actions';

import { getEntityTypeLabel } from '@constants/config';

import Button from '@material-ui/core/Button';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { getMetadata } from '@selectors/forms-selector';

import { isErrorsEmpty } from '@utils/form-utils';
import { isReadOnly } from '@utils/permission-utils';
import { showEntityScheduleContainsWarning } from '@utils/segment-schedule/common';

import '../../../forms/forms.scss';

const Footer = () => {
  const { dataType, id } = useParams();
  const dispatch = useDispatch();
  const { permissions } = useSelector(state => state.config);
  const { data, error, modified, options, saving } = useSelector(state => state.dataDetail);
  const metadata = useSelector(state => getMetadata(dataType)(state));

  const dataTypeDisplayName = useMemo(() => getEntityTypeLabel(dataType), [dataType]);

  const saveDetails = useCallback(
    () => {
      dispatch(saveAndStay(dataType, dataTypeDisplayName, data, metadata, options));
    },
    [data, dataType, dataTypeDisplayName, dispatch, metadata, options]
  );

  const hasWarnings = useMemo(() => showEntityScheduleContainsWarning(
    data?.start_date, data?.end_date, data?.segments
  ), [data?.start_date, data?.end_date, data?.segments]);

  // On read-only mode render an info message.
  const readOnly = useMemo(() => isReadOnly(dataType, permissions), [dataType, permissions]);

  // Returns true if the save button must be disabled.
  //
  // We must disable the button while we are saving the data
  // or if there are errors in the form or if data was not modified yet.
  const isDisabled = useMemo(
    () => saving || !isErrorsEmpty(data, error) || hasWarnings || !modified,
    [data, error, hasWarnings, modified, saving]
  );

  if (!data || !data.segments || !id) {
    return null;
  }

  if (readOnly) {
    return (
      <div styleName="footer-container footer-info-message-container">
        <div styleName="footer-info-icon">
          <InfoOutlinedIcon fontSize="inherit" htmlColor="#333333" />
        </div>
        <div styleName="footer-info-message">
          Edit is disabled on {dataTypeDisplayName} info.
        </div>
      </div>
    );
  }

  return (
    <div styleName="footer-container">
      {id && modified && <div styleName="footer-unsaved">Unsaved changes.</div>}
      <Button color="primary" disabled={isDisabled} onClick={saveDetails} variant="contained">
        SAVE
      </Button>
    </div>
  );
};

export default memo(Footer);
