/* eslint-disable guard-for-in */
import React, { memo, useCallback, useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { openDashboardDialog } from '@actions/dashboard-actions';
import {
  errorDataField,
  updateSegmentField,
  saveAndNavigate,
  saveAndStay,
  navigateAfterSave
} from '@actions/data-detail-actions';
import { uploadStoredAttachments } from '@actions/attachment-actions';

import NonFieldErrors from '@components/entity/info/drawer/form/non-field-errors';
import LocationContext from '@components/entity/info/location-context';
import { REQUIRED_ERROR } from '@components/forms/constants';
import DrawerFormTab from '@components/public/drawer-form-tab';

import * as colors from '@constants/colors';
import { getEntityTypeLabel } from '@constants/config';
import * as dialog from '@constants/dialogs';
import { paper } from '@constants/mui-theme';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { Button } from '@mui';

import { getMetadata } from '@selectors/forms-selector';
import BulkScheduleDialog from '@shared/dialogs/bulk-schedule-dialog';
import EditScheduleDialog from '@shared/dialogs/edit-schedule-dialog';
import ViewScheduleDialog from '@shared/dialogs/view-schedule-dialog';
import SubmitPublicFormDialog from '@shared/dialogs/submit-public-form-dialog';

import DotmapsLoader from '@shared/dotmaps-loader';

import { isErrorsEmpty } from '@utils/form-utils';

import '../forms/forms.scss';

const Drawer = () => {
  const dispatch = useDispatch();
  const { dataType } = useParams();
  const { data, error: errors, options, saving } = useSelector(state => state.dataDetail);
  const metadata = useSelector(state => getMetadata(dataType, true)(state));
  const { details } = useSelector(state => state.config);
  const [activeStep, setActiveStep] = useState(0);
  const {
    setActiveSegmentId,
    setActiveType
  } = useContext(LocationContext);

  const dataTypeDisplayName = useMemo(() => getEntityTypeLabel(dataType), [dataType]);

  const handleError = useCallback((field, error) => {
    dispatch(errorDataField(field, error, false));
  }, [dispatch]);

  const goToSuccessPage = useCallback(() => {
    // if we have a documents page, the save is already done
    // so the "submit" on the documents page just sends the user to the success page
    dispatch(navigateAfterSave('entity', data.id, `/publicform/${dataType}/success`));
  }, [dispatch, data.id, dataType]);

  const publicTabs = useMemo(() => {
    const tabs = details?.[dataType]?.tabs.filter(tab => !tab.hide_public);
    tabs.push({
      id: 'review',
      label: 'Review',
      step: tabs.length
    });
    details?.[dataType]?.public?.forEach(step => tabs.push({ step: tabs.length, ...step }));
    return tabs;
  }, [details, dataType]);

  const saveDisabled = useMemo(() => saving || !isErrorsEmpty(data, errors), [data, errors, saving]);

  const setSegmentActive = useCallback(
    step => {
      setActiveStep(step);
      if (!publicTabs[step].fields || publicTabs[step].fields[0] !== 'segments') {
        setActiveSegmentId(null);
      } else {
        setActiveSegmentId(data?.segments[0]?.id);
        setActiveType('LineString');
      }
    },
    [data.segments, publicTabs, setActiveSegmentId, setActiveStep, setActiveType]
  );

  const onSegmentFieldError = useCallback(
    (segmentId, fieldName, error) => {
      dispatch(updateSegmentField(segmentId, fieldName, null, error));
    },
    [dispatch]
  );

  const isStepValid = useCallback(() => {
    const tab = publicTabs[activeStep];
    let valid = true;
    for (const index in tab.fields) {
      const fieldName = tab.fields[index];
      if (fieldName === 'segments') {
        if (!data.segments[0].shape) {
          onSegmentFieldError(data.segments[0].id, 'display_from', 'Please enter an address.');
          onSegmentFieldError(data.segments[0].id, 'display_to', 'Please enter an address.');
          valid = false;
        }
      } else
        if (fieldName === '_date_range') {
          if (metadata?.end_date?.required) {
            if (!data.end_date) {  // eslint-disable-line max-depth
              handleError('end_date', REQUIRED_ERROR);
              valid = false;
            }
          }
          if (metadata?.start_date?.required) {
            if (!data.start_date) {  // eslint-disable-line max-depth
              handleError('start_date', REQUIRED_ERROR);
              valid = false;
            }
          }
        } else
          if (fieldName === '_date_warning' || fieldName === '_textarea' || fieldName === '_info_message') {
            // eslint-disable-line no-empty
          } else if (metadata?.[fieldName]?.required) {
            if (!data[fieldName]) {
              handleError(fieldName, REQUIRED_ERROR);
              valid = false;
            } else if (errors?.[fieldName]?.includes(REQUIRED_ERROR)) {
              handleError(fieldName, null);
            }
          }
    }
    return valid;
  }, [activeStep, data, errors, handleError, metadata, onSegmentFieldError, publicTabs]);

  const handleBack = useCallback(
    () => {
      const prevStep = activeStep - 1;
      setSegmentActive(prevStep);
      setActiveStep(prevStep);
      document.getElementById('form-container').scrollTop = 0;
    },
    [activeStep, setActiveStep, setSegmentActive]
  );

  const handleNext = useCallback(
    () => {
      if (isStepValid()) {
        const nextStep = activeStep + 1;
        setSegmentActive(nextStep);
        setActiveStep(nextStep);
        document.getElementById('form-container').scrollTop = 0;
      }
    },
    [activeStep, isStepValid, setActiveStep, setSegmentActive]
  );

  const activeTab = useMemo(() => publicTabs[activeStep], [activeStep, publicTabs]);

  const submitFiles = useCallback(() => {
    if (data.id) {
      dispatch(uploadStoredAttachments('entity', data.id, true)).then(
        () => goToSuccessPage()
      );
    }
  }, [dispatch, data.id, goToSuccessPage]);

  const saveData = useCallback(() => {
    if (activeStep < publicTabs.length - 1) {
      dispatch(saveAndStay(dataType, dataTypeDisplayName, data, metadata, options, true)).then(
        () => handleNext()
      );
    } else {
      const source = `/publicform/${dataType}/success`;
      dispatch(saveAndNavigate(dataType, dataTypeDisplayName, data, metadata, options, source, true));
    }
  }, [activeStep, dataType, data, dataTypeDisplayName, dispatch, metadata, handleNext, options, publicTabs]);

  const submitButton = useMemo(() => {
    let label = '';
    let onClick = null;

    if (activeTab.id === 'review') {
      label = 'SUBMIT';
      onClick = () => dispatch(openDashboardDialog(dialog.SUBMIT_PUBLIC_FORM, { submitForm: saveData }));
    } else if (activeTab.id === 'documents') {
      label = 'SUBMIT';
      onClick = () => dispatch(openDashboardDialog(dialog.SUBMIT_PUBLIC_FORM, { submitForm: submitFiles }));
    } else {
      label = 'NEXT';
      onClick = handleNext;
    }
    return (
      <Button color="primary" disabled={saveDisabled} key="apply" onClick={onClick} variant="contained">
        {label}
      </Button>
    );
  }, [activeTab, dispatch, handleNext, saveData, saveDisabled, submitFiles]);

  return (
    <div styleName="drawer-form-container" key="drawer" style={paper}>
      <div styleName="details-stepper-container" style={{ ...paper, boxShadow: 'none' }}>
        <Stepper activeStep={activeStep} nonLinear orientation="vertical">
          {publicTabs.map(tab => (
            <Step key={tab.label}>
              <StepLabel>{tab.label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div id="form-container" styleName="form-container" >
        <NonFieldErrors />
        {publicTabs.map(tab => tab.step === activeStep ? <DrawerFormTab key={tab.id} tabs={publicTabs} tab={tab} /> : null)}
        <div styleName="step-buttons">
          {saving &&
            <div style={{position: 'relative', padding: '1rem'}}>
              <DotmapsLoader color={colors.dotmapsBlue} display small />
            </div>
          }
          {activeStep !== 0 && activeTab.id !== 'documents' &&
            <Button
              color="primary"
              key="previous"
              onClick={handleBack}
              style={{ marginRight: '1rem' }}
            >
              PREVIOUS
            </Button>
          }
          {submitButton}
        </div>
      </div>
      <EditScheduleDialog />
      <BulkScheduleDialog />
      <ViewScheduleDialog />
      <SubmitPublicFormDialog />
    </div>
  );
};

export default memo(Drawer);
